@import '../../ui/functions.scss';

.header-component {
	background-color: val(background-color-1);
	width: 100%;
	height: 65px; //Update (page (style.css) - menu css) if you change this
	text-align: left;
	direction: ltr;
	padding: 0 8px;
	box-shadow: 0px 1px 1px val(border-color);
	position: relative;
	z-index: 201; // Header be top of Toast

	.items {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		list-style-type: none;
		margin: 0;
		padding: 8px 13px 0;
		align-items: center;

		.sidebar {
			width: 40px;
			height: 100%;
			display: inline-block;
			margin-top: 2px;

			img {
				width: 30px;
				height: 30px;
			}
		}

		.logo-static {
			height: 100%;
			margin: 7px 0 0 13px;
			display: inline-block;

			img {
				width: 180px;
			}
		}

		.logo {
			margin: 0 3px;

			.logo-dynamic {
				width: 45px;
				height: 45px;
			}
		}
	}
}
