
.dark {
	--background-color: #232425;
	--background-color-1: #292a2b;
	--background-color-2: #555555;
	--background-color-3: #353637;
	--text-color: #eee;
	--border-color: #4a4d4d;
	--basic-color: #717171;
	--basic-color-simple: #848484;
	--button-text-color: #f0f0f0;
}
