@import '../../ui/functions.scss';


// Remove Arrow for number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	font-family: val(global-font);
}

::-moz-placeholder { /* Firefox 19+ */
	font-family: val(global-font);
}

:-ms-input-placeholder { /* IE 10+ */
	font-family: val(global-font);
}

:-moz-placeholder { /* Firefox 18- */
	font-family: val(global-font);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-family: val(global-font);
}


.input-component {
	background-color: val(background-color-3);
	height: 55px;
	width: 177px;
	padding: 2px 3px;
	border-radius: 6px;
	border: 1px solid val(border-color);
	color: val(text-color);
	outline: none;
	box-shadow: none;
}

.input-component:hover:enabled, .input-component:active:enabled, .input-component:focus:enabled {
	//transition: all 0.2s ease;
	//-moz-filter: brightness(0.99);
	//-webkit-filter: brightness(0.99);
	//filter: brightness(0.99);
}


.input-hint {
	width: 100%;
	min-height: 40px;
	height: max-content;
	//display: table; Or we can use height: max-content;

	visibility: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid #dc143c;
	border-radius: 6px;
	padding: 0 3px;
	background-color: rgba(132,66,66,0.2);
	//text-align: center;


	p {
		//display: table-row; //Expanding a parent <div> to the height of its children
		color: #dc143c;
		font-size: small;
	}
}

