
.cosmic {
	--background-color: #38385f;
	--background-color-1: #4d4d78;
	--background-color-2: #4c4c8b;
	--background-color-3: #444474;
	--text-color: #eee;
	--border-color: #75759a;
	--basic-color: #945eff;
	--basic-color-simple: #b28ef9;
	--button-text-color: #f0f0f0;
}
