@import '../../ui/functions.scss';


@media only screen and (min-width: 575px) {

	.toast-component {

		.toast-item {
			width: 440px;
			max-height: 170px;
		}
	}
}

@media only screen and (max-width: 575px) {

	.toast-component {

		.toast-item {
			width: 320px;
			max-height: 150px;
		}
	}
}


.toast-component {
	position: fixed;
	z-index: 200;
	right: 0;
	bottom: 0;
	width: fit-content;
	display: flex;
	flex-direction: column;
	bottom: 25px;
	right: 15px;
	transition: all 0.22s ease-in-out;

	.toast-item { //Update addEventListner if you change this
		cursor: pointer;
		border-radius: 6px;
		border: solid 2px val(border-color);
		transition: all 0.22s ease-in-out;
		position: relative;
		margin-top: 8px;
		z-index: 201;

		.header-toast {
			background-color: val(background-color);
			height: 30px;
			display: flex;
			align-items: center; /* align vertical */
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;

			.image-container {
				display: inline-block;
				margin: 0 5px;

				img {
					width: 20px;
					height: 20px;
				}
			}

			b {
				margin: 0 3px;
			}
		}


		.body-toast {
			height: 100px;
			border-bottom-right-radius: 5px;
			border-bottom-left-radius: 5px;
			background-color: val(background-color-1);
			padding: 5px 13px;

			p {
				display: inline-block;
				margin-top: 3px;
			}
		}

		&:active, &:focus {
			opacity: 0.7;
		}
	}

	.show {
		visibility: visible;
		// -webkit-animation: in 0.3s, out 0.3s 3.7s;
		// animation: in 0.3s, out 0.3s 3.7s;
		animation: in 0.25s ease normal, out 0.25s ease 3.75s normal;
		-webkit-animation: in 0.25s ease normal, out 0.25s ease 3.75s normal;
	}

	.error {
		// background-color: #f03853;
		background-color: rgba(247, 54, 83, 0.7);
		-webkit-filter: drop-shadow(0px 0px 1px #ff0023);
		filter: drop-shadow(0px 0px 1px #ff0023);
	}

	.warning {
		background-color: #fa8822;
		-webkit-filter: drop-shadow(0px 0px 1px #ff7e00);
		filter: drop-shadow(0px 0px 1px #ff7e00);
	}

	.success {
		// background-color: #38c64c;
		background-color: rgba(38, 220, 64, 0.7);
		-webkit-filter: drop-shadow(0px 0px 1px #01e917);
		filter: drop-shadow(0px 0px 1px #01e917);
	}
	/*@keyframes in {
		from {
			bottom: 15px;
			right: 0;
			opacity: 0.1;
		}

		to {
			bottom: 25px;
			right: 15px;
			opacity: 1;
		}
	}

	@keyframes out {
		from {
			bottom: 25px;
			right: 15px;
			opacity: 1;
		}

		to {
			bottom: 15px;
			right: 0;
			opacity: 0;
		}
	}*/
}
