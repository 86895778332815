.light {
	--background-color: #eee;
	--background-color-1: #ffffff;
	--background-color-2: #f1f1f1;
	--background-color-3: #fcfcfc;
	--text-color: #222222;
	--border-color: #dddddd;
	--basic-color: #2595ec;
	--basic-color-simple: #7cbff3;
}
