@import '../../ui/functions.scss';


.api-page {

	.page-body {
		padding: 32px;
		-webkit-box-decoration-break: clone;
		box-decoration-break: clone;


		.icon {
			width: 25px;
			height: 25px;
			margin: 0 3px;
		}
	}

	.box {
		border-radius: 6px;
		border: 2px solid rgba(135, 207, 240, 0.8);
		margin: 21px 15px;
		padding: 13px;
		color: #45cae7;
		background-color: rgba(144, 226, 244, 0.1);
		overflow: auto;

		code {
			//white-space: break-spaces;
			color: #45cae7;
			font-size: medium;
		}
	}

	.box-warning {
		border: 2px solid rgba(247, 154, 34, 0.8);
		color: #c6a24b;
		background-color: rgba(231, 181, 117, 0.2);
	}

	.box-green {
		border: 2px solid rgba(135, 241, 165, 0.8);
		color: #81da9a;
		background-color: rgba(135, 241, 165, 0.2);
	}


	table {
		width: 100%;
		border-collapse: collapse; //Default is separate

		td, th {
			border: 1px solid val(border-color);
			text-align: left;
			padding: 8px;
			text-align: center;
			font-size: small;
		}

		tr:nth-child(even) {
			background-color: val(background-color-2);
		}
	}

	.content-container {

		.content-header {
			display: flex;
			justify-content: space-between;

			div {
				display: flex;
				align-items: center; //to centerize vertically
				margin: 0 13px;
			}

			img {
				width: 64px;
				height: 64px;
			}

			.page-header-title {
				margin: 0 8px;
			}
		}
	}
	/*
            .loader .l_main {
                width: 172px;
                height: 128px;
                margin: 20px auto;
            }

            .l_square {
                position: relative
            }

            .l_square:nth-child(1) {
                margin-left: 0px
            }

            .l_square:nth-child(2) {
                margin-left: 44px
            }

            .l_square:nth-child(3) {
                margin-left: 88px
            }

            .l_square:nth-child(4) {
                margin-left: 132px
            }

            .l_square span {
                position: absolute;
                top: 0px;
                left: 20px;
                height: 36px;
                width: 36px;
                border-radius: 2px;
                background-color: #FFFFFF
            }

            .l_square span:nth-child(1) {
                top: 0px
            }

            .l_square span:nth-child(2) {
                top: 44px
            }

            .l_square span:nth-child(3) {
                top: 88px
            }

            .l_square:nth-child(1) span {
                -webkit-animation: animsquare1 2s infinite ease-in;
                animation: animsquare1 2s infinite ease-in;
                background: #f11956;
            }

            .l_square:nth-child(2) span {
                -webkit-animation: animsquare2 2s infinite ease-in;
                animation: animsquare2 2s infinite ease-in;
                background: #21bef4;
            }

            .l_square:nth-child(3) span {
                -webkit-animation: animsquare3 2s infinite ease-in;
                animation: animsquare3 2s infinite ease-in;
                background: #41de50;
            }

            .l_square:nth-child(4) span {
                -webkit-animation: animsquare4 2s infinite ease-in;
                animation: animsquare4 2s infinite ease-in;
                background: #faab21;
            }

            .l_square span:nth-child(1) {
                -webkit-animation-delay: 0.00s;
                animation-delay: 0.00s;
            }

            .l_square span:nth-child(2) {
                -webkit-animation-delay: 0.15s;
                animation-delay: 0.15s;
            }

            .l_square span:nth-child(3) {
                -webkit-animation-delay: 0.30s;
                animation-delay: 0.30s;
            }

            @-webkit-keyframes animsquare1 {
                0%,5%,95%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                30%,70% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }

            @keyframes animsquare1 {
                0%,5%,95%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                30%,70% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }

            @-webkit-keyframes animsquare2 {
                0%,10%,90%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                35%,65% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }

            @keyframes animsquare2 {
                0%,10%,90%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                35%,65% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }

            @-webkit-keyframes animsquare3 {
                0%,15%,85%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                40%,60% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }

            @keyframes animsquare3 {
                0%,15%,85%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                40%,60% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }

            @-webkit-keyframes animsquare4 {
                0%,20%,80%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                45%,55% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }

            @keyframes animsquare4 {
                0%,20%,80%,100% {
                    -webkit-transform: translate(0px,0px) rotate(0deg);
                    transform: translate(0px,0px) rotate(0deg);
                }

                45%,55% {
                    -webkit-transform: translate(-40px,0px) rotate(-90deg);
                    transform: translate(-40px,0px) rotate(-90deg);
                }
            }*/
}
