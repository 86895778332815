@import '../../ui/functions.scss';


.button-component {
	//visibility: inherit;
	width: 178px;
	//min-height: 55px;
	height: 55px;
	background-color: val(color);
	border: 1px solid val(border-color);
	border-radius: 6px;
	text-align: center;
	outline: none;
	/*-moz-filter: drop-shadow(0px 0px 1px val(border-color));
	-webkit-filter: drop-shadow(0px 0px 1px val(border-color));
	filter: drop-shadow(0px 0px 1px val(border-color));*/
	padding: 0;


	.img {
		display: inline-block; // inline not work, innerHTML wont fit into element and go out, inline-block fit innerHTML!
		margin: 0 5px;
		width: 21px;
		height: 21px;
	}

	.imgInnerHTML {
		position: relative;
		top: 4px;
	}


	span {
		position: relative;
		color: #fefefe;
	}


	.load-container {
		position: relative; //Because of child absolute
		display: none;
		height: auto;
		width: 100%;
		text-align: center;

		.load {
			display: inline-block; // Verry important, idk why?!
			height: 0;
			width: 0;

			svg {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
				height: 53px;
				width: 53px;
			}
		}
	}


	&:hover:enabled, &:focus:enabled {
		background-image: linear-gradient(rgba(0, 0, 0, 0.05) 0 0);
		cursor: pointer;
	}


	&:active:enabled {
		background-image: linear-gradient(rgba(0, 0, 0, 0.13) 0 0);
	}
}
