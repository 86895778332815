@import '../../ui/functions.scss';

@media only screen and (min-width: 575px) {
	.footer-component {
		display: block;
		height: 220px; // Update page if you change this
		width: 100%;
		background-color: val(background-color-1);
		position: relative;
		box-shadow: 0px -1px 1px val(border-color);
		padding: 8px;
		z-index: 100;
	}
}


@media only screen and (max-width: 575px) {
	.footer-component {
		height: 0;
		display: none;
	}
}


.footer-component {

	.links {
		width: 100%;

		.urls {
			text-align: left;
			direction: ltr;
			float: left;
			margin: 10px 0 0 0;

			a {
				display: inline-block;
				text-decoration: none;
				margin: 3px 15px 10px 15px;
				color: #cbcbcb;
				font-size: 16px;
				white-space: nowrap;
				cursor: pointer;
			}
		}

		.socials {
			text-align: right;
			direction: rtl;
			float: right;
			padding: 0;
			margin: 10px 0 0 0;

			a {
				width: 64px;
				height: 64px;
				color: val(text-hint-color);
				transition: color ease-out 0.5s;
				margin: 0 5px;

				&:hover {
					color: val(text-basic-color);
				}
			}

			img {
				width: 30px;
				height: 30px;
				filter: opacity(70%);
			}

			img:hover {
				filter: opacity(100%);
			}
		}
	}

	.copyright_container {
		margin-top: 50px;

		.logo {
			text-align: center;

			.iodynamic_logo {
				width: 200px;
			}
		}

		.copyright {
			text-align: center;
		}
	}
}
