@import '../../ui/functions.scss';

.contact-us-page {

	.rtl {
		.form-input {
			text-align: right;
			direction: rtl;
		}
	}

	.page-body {

		.section-container {

			.section-header {
				padding: 21px 0 10px;

				.title-section {
					display: block;
					text-align: center;
				}
			}

			.section {
				max-width: 450px;
				margin: 13px 15px
			}

			.section-email {

				.item {
					width: contain; //100% is not inside section, contain is better choice
					margin: 8px 21px 0;

					.title {
						display: block;
						margin: 3px 0;
					}

					input {
						width: 220px;
					}

					.description {
						display: block;
						width: 90%;
						max-width: 90%;
						min-width: 90%;
						max-width: 600px;
						height: 210px;
						min-height: 210px;
						max-height: 210px;
						resize: none;
					}
				}
				/*
                    .captchaBox {
                        display: inline-block;
                        margin: 0;

                        .captha-text {
                            width: 120px;
                            display: block;
                        }

                        .captha {
                            height: 70px;
                            max-width: 180px;
                            border-radius: 8px;
                            display: inline-block;
                            box-shadow: 1px 1px 8px #a8a8a8;
                            margin-bottom: 5px;
                        }
                    }*/

				.btn-container {
					text-align: center;
					margin: 50px 0 20px;
				}
			}

			.section-about-us {

				.content {
					padding: 28px 21px;

					.matn {
						margin: 0 13px;
						line-height: normal;
					}

					.image {
						width: 42px;
						height: 42px;
					}
				}

				.title {
					padding: 8px 8px;
					display: inline-block;
				}

				.link {
					display: block;
					text-decoration: none;
				}

				.lastContent {
					border-bottom: none;
				}
			}
		}
	}
}
