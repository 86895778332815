.navy {
	--background-color: #252f4f;
	--background-color-1: #354169;
	--background-color-2: #243b52;
	--background-color-3: #2f3b5f;
	--text-color: #eee;
	--border-color: #5d6b93;
	--basic-color: #3663e2;
	--basic-color-simple: #587fee;
	--button-text-color: #f0f0f0;
}
